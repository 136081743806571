@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Mooli:regular'); 

body{
    width: 100vw;
    overflow-x: hidden;
    background: white;
    height:auto ;
    font-family:"mooli",sans-serif,system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}



@layer{
    .auth-section{
        @apply w-full h-screen overflow-hidden flex flex-col items-start justify-start px-6 py-4 gap-6;
    }
}